<template>
    <div class="power-dialog">
        <template v-if="type === 0">
            <el-form :model="power" label-width="90px" class="edit-form">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="权限名称:">
                            <el-tooltip effect="dark" :content="power.name" placement="top-start">
                                <span>{{ power.name }}</span>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="代码模块:">
                            <el-tooltip effect="dark" :content="power.code" placement="top-start">
                                <span>{{ power.code }}</span>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="权限状态:">
                            <span v-if="power.enable === 0">禁用</span>
                            <span v-else-if="power.enable === 1">启用</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="路由地址:">
                            <el-tooltip effect="dark" :content="power.route" placement="top-start">
                                <span>{{ power.route }}</span>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="创建时间:">{{ power.createTime | timeFilter }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="权限描述:">{{ power.description }}</el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </template>
        <!-- 新增/编辑内容-->
        <template v-if="[1, 2].includes(type)">
            <el-form :model="power" :rules="rules" label-width="100px" class="edit-form" ref="dialogForm">
                <el-form-item label="权限名称：" prop="name" class="fl width-half">
                    <el-input placeholder="请输入内容" v-model="power.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="代码模块：" prop="code" class="fl width-half">
                    <el-input placeholder="请输入内容" v-model="power.code" clearable></el-input>
                </el-form-item>
                <el-form-item label="路由地址：" prop="route" class="clearfix">
                    <el-input placeholder="请输入内容" v-model="power.route" clearable></el-input>
                </el-form-item>
                <el-form-item label="权限描述：" prop="description">
                    <el-input type="textarea" :autosize="{ minRows: 6 }" placeholder="请输入描述" v-model="power.description" clearable></el-input>
                </el-form-item>
            </el-form>
        </template>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-27 09:37:23
 * @LastEditTime: 2018-09-27 09:37:23
 * @Description: 权限设置 view/edit/add 弹出框
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        power: Object,
        type: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入权限名称', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入模块路径', trigger: 'blur' }
                ],
                route: [
                    { required: true, message: '请输入路由地址', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: '请输入权限描述', trigger: 'blur' }
                ]
            }
        };
    }
};
</script>

<style lang="scss">
    .power-dialog{
        padding: 20px;
        .el-form-item__content{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span{
                font-size: 14px;
            }
        }
    }
</style>
