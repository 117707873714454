<template>
    <div class="admin-power page-container flex-column">
        <!-- search-box -->
        <el-form class="search-box" ref="searchForm" align="center" :model="searchData" inline>
            <el-form-item prop="name">
                <el-input v-model="searchData.name" placeholder="输入权限名称" class="search-input" @keyup.enter.native.prevent="searchHandle">
                    <el-select prop="auditStatus" v-model="searchData.enable" slot="prepend" style="width:75px" placeholder="选择状态">
                        <el-option v-for="(item,index) in statusList" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search" @click="searchHandle"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button class="search-box-clear" type="text" @click="clearSearchHandle">清除搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- search-box-end -->
        <!-- <div class="admin-power-option flex-row mb10">
            <div class="mr10">已经择<span class="color-green"> {{allSelect.length}} </span>项</div>
            <el-button size="mini" type="primary" @click="changeDialog(true, '新增权限', 1, {})">新增权限</el-button>
            <el-button size="mini" type="danger" @click="removeListener">批量删除</el-button>
        </div> -->
        <el-table class="admin-power-table" ref="table" :data="tableList" border style="width: 100%" v-loading="isloading" @selection-change="selectionChangeHandle">
            <!-- <el-table-column type="selection" width="55"></el-table-column> -->
            <el-table-column label="权限名称" prop="name">
                <template slot-scope="scope">
                    <el-tooltip effect="light" :content="scope.row.name" placement="top-start">
                        <el-button type="text" @click="changeDialog(true, '权限详情', 0, scope.row)">{{scope.row.name}}</el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="代码模块" prop="code"></el-table-column>
            <el-table-column label="路由地址" prop="route">
                <template slot-scope="scope">
                    <el-tooltip effect="light" :content="scope.row.route" placement="top-start">
                        <div class="width100 single-ellipsis">{{scope.row.route}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="权限描述" prop="description"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="150">
                <template slot-scope="scope">
                    <span>{{scope.row.createTime | timeFilter('yyyy-MM-dd hh:mm:ss')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="enable" width="80">
                <template slot-scope="scope">
                    <div class="admin-power-status enabled" v-if="scope.row.enable">已启用</div>
                    <div class="admin-power-status disable" v-else>已禁用</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <!-- <el-button type="text" @click="removeListener(scope.$index, scope.row)">删除</el-button> -->
                    <el-button v-if="scope.row.enable" type="text" @click="enableChangeHandle(0, scope.row)">禁用</el-button>
                    <el-button v-else type="text" @click="enableChangeHandle(1, scope.row)">启用</el-button>
                    <el-button type="text" @click="changeDialog(true, '编辑权限', 2, scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <dialogPlus :visible.sync="dialogData.visible" :title.sync="dialogData.title" width="700px" :model="dialogData.data" :heightFull="false" @close="closeDialog">
            <powerDialogContent :power.sync="dialogData.data" :type="dialogData.type" ref="powerDialogContent"/>
            <div slot="footer" align="center">
                <template v-if="dialogData.type === 0">
                    <el-button @click="changeDialog(true, '编辑权限', 2, dialogData.data)">编 辑</el-button>
                    <el-button type="primary" @click="dialogCloseHandle">确 定</el-button>
                </template>
                <template v-else>
                    <el-button @click="dialogCloseHandle">取 消</el-button>
                    <el-button type="primary" @click="addAndEditSubmitHandle(dialogData.data)">确 定</el-button>
                </template>
            </div>
        </dialogPlus>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-12 20:17:44
 * @Description: 管理中心-权限设置
 * @Company: 成都二次元动漫
 */
import { searchMixin, paginationMixin, dialogMixin, formMixin } from '@/mixins';
import powerDialogContent from './powerDialogContent';
import PScroll from 'perfect-scrollbar';

export default {
    mixins: [searchMixin, paginationMixin, dialogMixin, formMixin],
    data() {
        return {
            isloading: false,
            searchData: {
                name: null,
                enable: -1,
                order: 1
            },
            tableList: [],
            statusList: [
                { label: '所有', value: -1 },
                { label: '禁用', value: 0 },
                { label: '启用', value: 1 }
            ],
            allSelect: []
        };
    },
    computed: {
        codeList() {
            return this.allSelect.map(power => power.code);
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {
        this.pscroll = new PScroll(document.querySelector('.admin-power-table .el-table__body-wrapper'), {
            handlers: ['click-rail', 'keyboard', 'wheel', 'touch'],
            maxScrollbarLength: 0
        }).element;
    },
    methods: {
        // 搜索
        searchHandle() {
            this.getTableList();
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetForm('searchForm');
            this.searchData.enable = -1;
            this.searchHandle();
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            this.$api('getAdminPermissionList', { ...this.searchData, ...this.pagination }).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { rows, pageIndex, pageSize, total } = res.data;
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
                this.isloading = false;
            });
        },
        // 行选择
        selectionChangeHandle(list) {
            this.allSelect = list;
        },
        // 新增、编辑权限确认按钮
        addAndEditSubmitHandle(data) {
            const [formEl] = this.$refs.powerDialogContent.$children;
            formEl.validate(valid => {
                if (data.id) this.submit('editAdminPermission', data);
                else this.submit('addAdminPermission', data);
            });
        },
        // 新增、修改提交方法
        submit(url, data) {
            this.$utils.confirm('', '确定提交吗？', '提交中...', (action, instance, done) => {
                return this.$api(url, data).then(res => {
                    this.getTableList();
                    this.dialogCloseHandle();
                });
            });
        },
        // 启用、禁用权限
        enableChangeHandle(status, { code }) {
            const msg = status ? '启用' : '禁用';
            this.$utils.confirm('', `确定${msg}选中权限吗？`, `${msg}中...`, (action, instance, done) => {
                return this.$api('enableAdminPermission', { codeList: [code], status }).then(res => {
                    this.getTableList();
                });
            });
        },
        // 删除权限-可批量
        removeListener(index, row) {
            if (!this.codeList.length && !row) return;
            const codeList = row ? [row.code] : this.codeList;

            this.$utils.confirm('', '确定删除选中权限吗？', '删除中...', (action, instance, done) => {
                return this.$api('removeAdminPermission', { codeList }).then(res => {
                    this.getTableList();
                });
            });
        },
        // 关闭dialog
        closeDialog() {
            const [formEl] = this.$refs.powerDialogContent.$children;
            formEl.clearValidate();
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    },
    components: {
        powerDialogContent
    }
};
</script>

<style lang="scss">
    @import '../../../../styles/var';
    .admin-power{
        &-status{
            &::before{
                display: inline-block;
                content: " ";
                width: 3px;
                height: 6px;
                border-radius: 3px;
                margin-right: 10px;
                vertical-align: middle;
            }
            &.enabled::before{
                background: $color-success;
            }
            &.disable:before{
                background: $color-danger;
            }
        }
        &-option{
            height: 28px;
            align-items: center;
        }
        &-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow: hidden;
            }
        }
        .search-input {
            width: 524px;
        }
    }
</style>
